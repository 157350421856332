import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
    // {
    //     path: '',
    //     redirectTo: 'appointments',
    //     pathMatch: 'full'
    // },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: '',
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'calendar',
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'appointments',
        loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'appointments/:id',
        loadChildren: () => import('./appointments/appointment/appointment.module').then(m => m.AppointmentModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'companies',
        loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'users/:id',
        loadChildren: () => import('./users/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'assessment-form/:form/:action/:id',
        loadChildren: () => import('./assessment-form/assessment-form.module').then(m => m.AssessmentFormModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'providers',
        loadChildren: () => import('./providers/providers.module').then(m => m.ProvidersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'providers/:id',
        loadChildren: () => import('./providers/provider/provider.module').then(m => m.ProviderModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'senior-centers',
        loadChildren: () => import('./senior-centers/senior-centers.module').then(m => m.SeniorCentersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'services',
        loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'kpi-data-entry',
        loadChildren: () => import('./kpi-data-entry/kpi-data-entry.module').then(m => m.KpiDataEntryModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'kpi-data-entry/department/:id',
        loadChildren: () => import('./kpi-data-entry/department/department.module').then(m => m.DepartmentModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'kpi-data-entry/information-referral-form',
        loadChildren: () => import('./kpi-data-entry/form/information-referral/information-referral.module').then(m => m.InfoReferralModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'kpi-data-entry/information-referral-form/:id',
        loadChildren: () => import('./kpi-data-entry/form/information-referral/information-referral.module').then(m => m.InfoReferralModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'kpi-data-entry/form/:action/:date/:id',
        loadChildren: () => import('./kpi-data-entry/form/form.module').then(m => m.FormModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin-users',
        loadChildren: () => import('./admin-users/admin-users.module').then(m => m.AdminUsersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'activity-report',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports-builder',
        loadChildren: () => import('./reports-builder/reports-builder.module').then(m => m.ReportsBuilderModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'audit-trail',
        loadChildren: () => import('./audit-trail/audit-trail.module').then(m => m.AuditTrailModule),
        canActivate: [AuthGuard]
    },
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
